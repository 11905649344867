import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><strong parentName="p"><em parentName="strong">{`Happy Easter!  We will be closed today but you can do this one at
home!`}</em></strong></p>
    <p>{`Single Leg Jump Squats 3×12/leg (trailing leg on box/bench)`}</p>
    <p>{`then,`}</p>
    <p>{`1 Mile Run`}</p>
    <p>{`50 Pushups`}</p>
    <p>{`75 Situps`}</p>
    <p>{`100 Squats`}</p>
    <p>{`1 Mile Run`}</p>
    <p>{`For time.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      